import { defineStore } from 'pinia'
import { api } from '@/plugins/api'
import { StatEnum } from '@/enums'

export const useStatisticsStore = defineStore('statistic', {
  state: () => ({
    [StatEnum.APPOINTMENTS_PUBLIC_TOTAL]: getNumberToCad('175501'),
    [StatEnum.APPOINTMENTS_PUBLIC_WITHOUT_FEES]: getNumberToCad('163554'),
    [StatEnum.APPOINTMENTS_PRIVATE_TOTAL]: getNumberToCad('10715'),
    [StatEnum.APPOINTMENTS_PRIVATE_WITHOUT_FEES]: getNumberToCad('5617'),
    [StatEnum.TOTAL_APPOINTMENTS]: getNumberToCad('186216'),
    [StatEnum.TOTAL_APPOINTMENTS_WITHOUT_FEES]: getNumberToCad('169171'),
    [StatEnum.PERCENTAGE_APPOINTMENTS_PUBLIC_WITHOUT_FEES]: getNumberToCad('93.2'),
    [StatEnum.PERCENTAGE_APPOINTMENTS_PRIVATE_WITHOUT_FEES]: getNumberToCad('52.4'),
    [StatEnum.PERCENTAGE_APPOINTMENTS_LAST_7_DAYS_WITHOUT_FEES]: getNumberToCad('90.8'),
    fetched: false,
    display: false
  }),
  actions: {
    async fetchStatistics() {
      if (this.fetched) return

      const response = await api.statistics.getStatistics()
      this.fetched = true

      this[StatEnum.APPOINTMENTS_PUBLIC_TOTAL] = getNumberToCad(
        response.data[StatEnum.APPOINTMENTS_PUBLIC_TOTAL]
      )
      this[StatEnum.APPOINTMENTS_PUBLIC_WITHOUT_FEES] = getNumberToCad(
        response.data[StatEnum.APPOINTMENTS_PUBLIC_WITHOUT_FEES]
      )
      this[StatEnum.APPOINTMENTS_PRIVATE_TOTAL] = getNumberToCad(
        response.data[StatEnum.APPOINTMENTS_PRIVATE_TOTAL]
      )
      this[StatEnum.APPOINTMENTS_PRIVATE_WITHOUT_FEES] = getNumberToCad(
        response.data[StatEnum.APPOINTMENTS_PRIVATE_WITHOUT_FEES]
      )
      this[StatEnum.TOTAL_APPOINTMENTS] = getNumberToCad(response.data[StatEnum.TOTAL_APPOINTMENTS])
      this[StatEnum.TOTAL_APPOINTMENTS_WITHOUT_FEES] = getNumberToCad(
        response.data[StatEnum.TOTAL_APPOINTMENTS_WITHOUT_FEES]
      )
      this[StatEnum.PERCENTAGE_APPOINTMENTS_PUBLIC_WITHOUT_FEES] = getNumberToCad(
        response.data[StatEnum.PERCENTAGE_APPOINTMENTS_PUBLIC_WITHOUT_FEES]
      )
      this[StatEnum.PERCENTAGE_APPOINTMENTS_PRIVATE_WITHOUT_FEES] = getNumberToCad(
        response.data[StatEnum.PERCENTAGE_APPOINTMENTS_PRIVATE_WITHOUT_FEES]
      )
      this[StatEnum.PERCENTAGE_APPOINTMENTS_LAST_7_DAYS_WITHOUT_FEES] = getNumberToCad(
        response.data[StatEnum.PERCENTAGE_APPOINTMENTS_LAST_7_DAYS_WITHOUT_FEES]
      )
    }
  }
})

function getNumberToCad(number) {
  return new Intl.NumberFormat('fr-FR').format(number)
}
